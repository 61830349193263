

































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../../store';
import { ScopeOfFunctions } from '../../types';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import CreateScopeOfFunctionsDialog from '../components/CreateScopeOfFunctionsDialog.vue';
import DeleteScopeOfFunctionsDialog from '../components/DeleteScopeOfFunctionsDialog.vue';
import EditScopeOfFunctionsDialog from '../components/EditScopeOfFunctionsDialog.vue';
import AppTable from '@/components/AppTable.vue';

const ScopeOfFunctions = namespace('scopeOfFunctions');

@Component({
  components: {
    ListSearchDialog,
    AppTable,
    EditScopeOfFunctionsDialog,
    DeleteScopeOfFunctionsDialog,
    CreateScopeOfFunctionsDialog,
    ErrorAlert
  }
})
export default class Overview extends Vue {
  @ScopeOfFunctions.Getter(GET_LIST) list!: ScopeOfFunctions[];
  @ScopeOfFunctions.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse<ScopeOfFunctions[]>>;

  search: string = '';
  error: any = null;
  loading: boolean = false;
  createDialog: boolean = false;
  editScopeOfFunctions: ScopeOfFunctions | null = null;
  deleteScopeOfFunctions: ScopeOfFunctions | null = null;

  pagination: Pagination = {
    sortBy: ['name'],
    itemsPerPage: -1
  };

  get items() {
    return this.list.map((scopeOfFunctions) => ({
      ...scopeOfFunctions,
      rolesTrans: scopeOfFunctions.roles
        .map((role) => this.$i18n.t(`scopeOfFunctions.role.${role}`))
        .join(', ')
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('label.roles'), value: 'rolesTrans' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }

  async created() {
    this.loading = true;
    this.error = null;

    const { error } = await this.fetch();

    this.loading = false;

    if (error) {
      this.error = error;
    }
  }
}
