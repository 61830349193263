





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EDIT_SCOPE_OF_FUNCTIONS } from '../../store';
import { ScopeOfFunctions, WriteScopeOfFunctions } from '../../types';
import ScopeOfFunctionsRoleSelect from './ScopeOfFunctionsRoleSelect.vue';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '../../model';

const ScopeOfFunctions = namespace('scopeOfFunctions');

@Component({
  components: {
    ScopeOfFunctionsRoleSelect
  }
})
export default class EditScopeOfFunctionsDialog extends Vue {
  @Prop() value!: ScopeOfFunctions | null;

  @ScopeOfFunctions.Action(EDIT_SCOPE_OF_FUNCTIONS) editScopeOfFunctions!: (scopeOfFunctions: WriteScopeOfFunctions) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  scopeOfFunctions: WriteScopeOfFunctions = {
    scope_of_functions_id: '',
    name: '',
    roles: []
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchTermSet(scopeOfFunctions: ScopeOfFunctions) {
    if (!scopeOfFunctions) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.scopeOfFunctions = {
      scope_of_functions_id: '',
      name: '',
      roles: []
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  success() {
    this.dialog = false;
    this.$emit('success')
  }

  init() {
    if (!this.value) return;

    this.scopeOfFunctions = MapViewToWrite(this.value);
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.editScopeOfFunctions(this.scopeOfFunctions);
  }
}
