



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import ScopeOfFunctionsRoleSelect from './ScopeOfFunctionsRoleSelect.vue';
import { CREATE_SCOPE_OF_FUNCTIONS } from '../../store';
import { WriteScopeOfFunctions } from '@/modules/scopeOfFunctions/types';
import { ApiResponse } from '@/components/types';

const ScopeOfFunctions = namespace('scopeOfFunctions');

@Component({
  components: {
    ScopeOfFunctionsRoleSelect
  }
})
export default class CreateScopeOfFunctionsDialog extends Vue {
  @Prop({ default: false }) value!: boolean;

  @ScopeOfFunctions.Action(CREATE_SCOPE_OF_FUNCTIONS) createScopeOfFunctions!: (data: WriteScopeOfFunctions) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  name: string = '';
  roles: string[] = [];

  get dialog() {
    return this.value;
  }

  set dialog(value) {
    this.$emit('input', value);
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (!value) {
      setTimeout(() => {
        this.clear();
      }, 200);

      return;
    }

    this.init();
  }

  clear() {
    this.name = '';
    this.roles = [];

    this.error = null;
    this.loading = false;
    this.dialog = false;
    // @ts-ignore
    this.$refs.form.reset();

    this.$emit('success')
  }

  init() {
    this.name = '';
    this.roles = [];
  }

  onError(error: any) {
    this.loading = false;
    this.error = error;
  }

  async save() {
    this.error = null;
    this.loading = true;

    return this.createScopeOfFunctions({
      scope_of_functions_id: uuid(),
      name: this.name,
      roles: this.roles
    });
  }
}
