


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class ScopeOfFunctionsRoleSelect extends Vue {
  @Prop() value?: string | string[];
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: true }) multiple!: boolean;

  get items() {
    return [
      { name: 'finances', label: this.$i18n.t(`scopeOfFunctions.role.finances`) }
    ];
  }
}
