






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AsyncDeleteButton from '@/components/form/AsyncDeleteButton.vue';
import { DELETE_SCOPE_OF_FUNCTIONS } from '../../store';
import { ScopeOfFunctions, WriteScopeOfFunctions } from '../../types';
import { MapViewToWrite } from '../../model';
import { ApiResponse } from '@/components/types';

const ScopeOfFunctions = namespace('scopeOfFunctions');

@Component({
  components: { AsyncDeleteButton, ErrorAlert }
})
export default class DeleteScopeOfFunctionsDialog extends Vue {
  @Prop() value!: ScopeOfFunctions | null;

  @ScopeOfFunctions.Action(DELETE_SCOPE_OF_FUNCTIONS) deleteScopeOfFunction!: (data: WriteScopeOfFunctions) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.$emit('input', null);
    }, 200);
  }

  @Watch('value')
  watchTermSet(scopeOfFunctions: ScopeOfFunctions) {
    if (!scopeOfFunctions) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  success() {
    this.dialog = false;
    this.$emit('success')
  }

  async remove() {
    if (!this.value) return;

    this.error = null;
    return this.deleteScopeOfFunction(MapViewToWrite(this.value));
  }
}
